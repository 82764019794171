@import './../../../../assets/styles/colors';
@import './../../../../assets/styles/utils';

$anim-fast: 0.7s;
$anim-medium: 1.4s;
$anim-slow: 2.1s;

.koybot.desktop {
  position: fixed;
  right: 0;
  top: calc(50% - 83px);
  width: 156px;
  height: 166px;
  &:hover{
      .header{
          transition: opacity $anim-fast ease;
          -webkit-transition: opacity $anim-fast ease;
          opacity: 1;
      }
      .circle-1{
        transition: opacity $anim-medium ease;
        -webkit-transition: opacity $anim-medium ease;
        opacity: 1;
      }
      .circle-2{
        transition: opacity $anim-slow ease;
        -webkit-transition: opacity $anim-slow ease;
        opacity: 1;
      }
  }
  .header {
    transition: opacity $anim-slow ease;
    -webkit-transition: opacity $anim-slow ease;
    opacity: 0;
    font-family: 'Montserrat';    
    font-weight: 600;
    font-size: 14px;
    text-align: right;
    color: $gray-medium;
    background: $white-text;
    border: 1px solid $white-text;
    box-sizing: border-box;
    border-radius: 16px;
    padding: 5px 7px;
  }
  .circle-1,.circle-2{
    opacity: 0;
      position: absolute;
      border-radius: 20px;
      background: $white-text;
  }
  .circle-1 {
    transition: opacity $anim-medium ease;
    -webkit-transition: opacity $anim-medium ease;
    width: 17px;
    height: 17px;
    left: 131px;
    top: 34px;
  }
  .circle-2 {
    transition: opacity $anim-fast ease;
    -webkit-transition: opacity $anim-fast ease;
    width: 11px;
    height: 11px;
    left: 121px;
    top: 54px;
  }
  .circle-icon {
    position: absolute;
    width: 104px;
    height: 104px;
    left: 44px;
    top: 65px;
    background: black;
    border-radius: 69px;
    cursor:pointer;
    &:before {
      $border: 4px;
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      margin: -$border; /* !importanté */
      border-radius: inherit; /* !importanté */
      background: linear-gradient(to right, $koyku-green, $koyku-red);
    }

    img {
      position: absolute;
      top: 12px;
      left: 16px;
    }
  }
}

.koybot-section-mobile{
  .arroba{
    font-size: 60px;
    color: $white-text;
  }
  .paragraph{
    padding-left: 0;
    padding-right: 0;
  }
  .lets-chat-text{
    font-family: 'Montserrat';
    font-size: 16px;
line-height: 20px;
color: $koyku-grey;
  }
  .lets-chat-icon{
    img{
      width: 70px;
    }
  }
  .right,.left{
    padding: 0 30px;
  }
  .right{
    text-align: right;
  }
  .left{
    text-align: left;
  }
}