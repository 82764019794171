/*Override*/
.p-avatar.p-avatar-lg {
  height: 4.4rem;
}

.stack-container {
  justify-content: space-around;
  gap: 58px 73px;

  display: inline-flex;
  flex-wrap: wrap;
  position: relative;


  &.mobile{
    justify-content: space-evenly;
    padding-left: 0;
    padding-right: 0;
    .technology{
      height: 100px;
      width: 100px;
    }
    .logo{
      max-width: 65px;
    }
  }



  .technology {
    &:hover {
      
      .gradient-background {
        &:before {
          background: linear-gradient(to bottom, rgba(16, 105, 137, 0), rgba(16, 105, 137, 1));
        }
      }
      .technology-name{
        display: block;
        width: 95%;
      }
      .logo{
        filter: blur(4px) brightness(0.5);
      }
    }

    height: 140px;
    width: 140px;

    background-color: black;
    border-radius: 100px;
    position: relative;
    text-align: center;

    display: flex;
    justify-content: center;
    align-items: center;
    .gradient-background {
      border-radius: 100px;
    }
    .logo {
      position: relative;
    }
    .technology-name{
      color: white;
      font-weight: 600;
      font-size: 22px;
      letter-spacing: -0.015em;
      position: absolute;
      text-transform: capitalize;
      display: none;
    }
  }
}
