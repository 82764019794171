@import '../../../assets/styles/colors';
@import '../../../assets/styles/utils';

.chat {
  padding: 0.75rem;
  border: solid 1px;
  border-radius: 10px;
  word-wrap: break-word;
  width: 20rem;
}

i {
  cursor: pointer;
}

.full-width{
  position: relative;
  .icon{
    position: absolute;
    right: 42px !important;
    top: 42px;
    cursor: pointer;
  }
  .p-inputtext{
    padding: 15px 50px 15px 10px;
  }
}