@import './../../../assets/styles/colors';
@import './../../../assets/styles/utils';

.footer {
  &.desktop {
    color:white;
    margin-top: 100px;
    display: block;
    background-color: $koyku-red;
    height: 208px;
    display: flex;
    .social-networks,
    .other-links {
      flex-grow: 1;
      text-align: center;
      align-items: center;
      display: grid;
      width: 50%;
    }

    .social-networks {
      font-family: Montserrat;
      font-weight: bold;
      font-size: 32px;
      line-height: 49px;
      letter-spacing: -0.015em;
      color: $koyku-grey;
      @include tablet {
        font-size: 30px;
      }
      @include desktop {
        font-size: 32px;
      }

      .social-networks__header {
        color:white;
        @include tablet {
          margin-bottom: 22px;
        }
        @include desktop {
          margin-bottom: 44px;
        }
        display: inline-block;
      }
      .social-networks__links-container {
        a img{
          height: 50px !important;
          width: 50px !important;
        }
      }
      a {
        @include tablet {
          margin: 0 10px;
        }
        @include desktop {
          margin: 0 15px;
        }
        &:hover{
          filter: brightness(0.85);
        }
      }
    }
    .other-links {
      background: $gray-trans-purple-27;
      > * {
        height: 100%;
        width: 60%;
        margin: 0 auto;
        font-weight: 500;
        font-size: 22px;
        //color: $koyku-grey;
        color:white ;
        text-decoration: none;
        &:before {
          content: '';
          display: inline-block;
          vertical-align: middle;
          height: 100%;
        }
      }
      .kopyright {
        display: inline-block;
        border-top: solid 1px $gray-trans-purple-27;
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
  &.mobile {
    margin-top: 50px;
    background-color: $koyku-red;
    
    .social-networks__container {
      position: relative;
      .social-networks__header {
        display: block;
        font-weight: bold;
        font-size: 26px;
        text-align: right;
        letter-spacing: -0.015em;
        padding: 32px 10%;
        // width: 350px;
        margin-left: auto;
        color:white;
      }
      .social-networks__links-container {
        padding: 0 5% 30px;
        text-align: center;
        a {
          text-align: center;
          width: 20%;
          display: inline-block;
          img{
            height: 50px !important;
            width: 50px !important;
          }
        }
      }
    }
    .other-links {
      margin: 0 15px;
      border-top: solid 1px $gray-trans-purple-27;
      * {
        font-family: 'Montserrat';
        margin: 0 auto;
        padding: 21px;
        text-align: center;
        font-weight: 500;
        font-size: 16px;
        //color: $koyku-grey;
        color:white;
        text-decoration: none;
        display: block;
      }
      .kopyright {
        background: $gray-trans-purple-27;
        font-weight: normal;
        font-size: 14px;
        color: $koyku-grey;
        margin: 0 -15px;
        padding-top: 11px;
      }
    }
  }
}

// .p-sidebar-content{
//   .footer.mobile{
//     margin: 0 -29px;
//   }
// }
