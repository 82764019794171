@import './../../../assets/styles/colors';
@import './../../../assets/styles/utils';

.steps-summary {
  position: relative;
  height: 19px;
  .track {
    position: absolute;
    background: #000000;
    border: 1px solid #525252;
    box-sizing: border-box;
    border-radius: 3px;
    height: 9px;
    top: 5px;
    left: 0;
    right: 0;
  }
  .points-container {
    position: absolute;
    width: calc(100% - 15px);
  }
  .point {
    position: absolute;
    height: 15px;
    width: 15px;
    border-radius: 50px;
    left: 0%;
    top: 2px;
    background: rgba(166, 166, 166, 1);
    cursor: pointer;
    &.current {
      background-color: $koyku-red;
      height: 19px;
      width: 19px;
      top: 1px;
    }
    .pointName{
        position: absolute;
        top:26px;
        width: max-content;
        @media (max-width: 650px) {
          font-size: 13px;
        }
    }
    &:first-child .pointName{
        left: 0;
    }
    &:last-child .pointName{
        right: 0;
    }
  }
}
