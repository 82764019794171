@import './../../../../../assets/styles/colors';
@import './../../../../../assets/styles/utils';

.our-work-detail {
  &.mobile {
  }
  position: relative;
  .steps {
    position: sticky;
    top: 0;
    z-index: 1;
    background: #3e3d3f;
    height: 111px;
    width: 100%;
    padding: 18px;
    .stepDate {
      margin-bottom: 14px;
    }
  }
  .scrollable-detail {
    // padding-top: 213px;
  }
  .our-work-description {
    margin-top: 37px;
    padding: 0 34px;
  }
  .steps-details {
    padding: 0 34px;
    width: 100%;
    .step-detail {
      .step-detail__title {
        display: block;
        font-weight: 500;
        font-size: 26px;
        line-height: 45px;
        letter-spacing: -0.015em;
        color: #ffffff;
        margin: 28px 0 23px 0;
      }
      .step-detail__content {
        position: sticky;
        min-height: 100px;

        .step-detail__content-row {
          position: relative;
          padding: 34px;
          .image-column {
            max-width: 100%;
            img {
              max-width: 100%;
            }
          }
          .text {
            
            @media (min-width: 992px) {
              padding-left: 34px;
            }
            margin: 20px 0;
            
          }
          .stack {
            width: calc(100% + 51px);
            height: 117px;
            margin-bottom: -34px;
            border-bottom-left-radius: 10px !important;
            border-bottom-right-radius: 10px !important;
            background: rgba(255, 255, 255, 0.1);
            &.hidden {
              display: none;
            }
            .title {
              font-weight: 600;
              font-size: 14px;
              line-height: 20px;
              letter-spacing: -0.015em;
              color: #ffffff;
            }
            .stack-group {
              .stack-item {
                position: relative;
                display: block !important;
                width: 50px;
                height: 50px;
                z-index: 1;
                margin: 0 10px;
                .gradient-background {
                  border-radius: 50px;
                }
                .p-image {
                  height: 100%;
                  width: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  img {
                    max-width: 30px;
                    position: relative;
                    max-height: 30px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .external-chat {
    z-index: 1;
    margin-bottom: 30px;
    display: inline-flex;
    position: relative;
    width: 100%;
    justify-content: right;
    padding: 34px 34px 0 0;
    .koybotIcon {
      margin-right: 35px;
    }
    koyku-button {
      height: 44px;
    }
  }
}
