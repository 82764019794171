.sidebar-left-button-group{
    position: fixed;
    top: 25px;
    left: 11px;
    .back-icon{
        font-weight: 900;
        font-size: 19px;
        margin-right: 9px;
        cursor: pointer;
        // padding: 15px;
        &.hide{
            display: none;
        }
    }
    .koyku-icon{
        height: 45px;
        width: 45px;
        cursor:pointer;
    }
    .header-title{
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        color: #FFFFFF;
    }
}