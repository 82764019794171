/*FONTS*/
@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Regular.eot');
  src: url('../fonts/Montserrat-Regular.eot?#iefix') format('embedded-opentype'), url('../fonts/Montserrat-Regular.woff') format('woff'),
    url('../fonts/Montserrat-Regular.woff2') format('woff2'), url('../fonts/Montserrat-Regular.ttf') format('truetype'),
    url('../fonts/Montserrat-Regular.svg#fontname') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Montserrat Alternates';
  src: url('../fonts/MontserratAlternates-Regular.eot');
  src: url('../fonts/MontserratAlternates-Regular.eot?#iefix') format('embedded-opentype'), url('../fonts/MontserratAlternates-Regular.woff') format('woff'),
    url('../fonts/MontserratAlternates-Regular.woff2') format('woff2'), url('../fonts/MontserratAlternates-Regular.ttf') format('truetype'),
    url('../fonts/MontserratAlternates-Regular.svg#fontname') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Montserrat Alternates Light';
  src: url('../fonts/Montserrat Alternates Light.eot');
  src: url('../fonts/Montserrat Alternates Light.eot?#iefix') format('embedded-opentype'), url('../fonts/Montserrat Alternates Light.woff') format('woff'),
    url('../fonts/Montserrat Alternates Light.woff2') format('woff2'), url('../fonts/Montserrat Alternates Light.ttf') format('truetype'),
    url('../fonts/Montserrat Alternates Light.svg#fontname') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Montserrat Alternates Bold';
  src: url('../fonts/Montserrat Alternates Bold.eot');
  src: url('../fonts/Montserrat Alternates Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/Montserrat Alternates Bold.woff') format('woff'),
    url('../fonts/Montserrat Alternates Bold.woff2') format('woff2'), url('../fonts/Montserrat Alternates Bold.ttf') format('truetype'),
    url('../fonts/Montserrat Alternates Bold.svg#fontname') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/*CONSTANTS*/
$ZEROREM: 0rem;
$ONEHUNDREDPERCENT: 100%;
$ANIMATIONDURATION: .160s;
$ANIMATIONTYPE: ease-in-out;
$ANIMATIONDEFAULT: all $ANIMATIONDURATION $ANIMATIONTYPE;

/*RESPONSIVE BREAKPOINTS*/
$tablet-width: 768px;
$desktop-width: 1024px;
@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

/*CLASSES*/
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.full-width {
  width: $ONEHUNDREDPERCENT;
}
