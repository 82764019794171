.home-page{
    &.mobile{
        margin:0 30px;
    }
    h1{
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-bottom: 25px !important;
        padding-top: 55px !important;
    }
}