$koyku-red: #d23f40;
$koyku-red-secondary:#D73031; 
$koyku-green: #106989;
$koyku-green-secondary: #238EB5;
$koyku-grey: #d3d3cb;
$white-text: #f9f9f9;
$gray-dark: #29282a;
$gray-medium: #3e3d3f;
$white: #FFFFFF;

//Unidentified colors
$gray-light: #c4c4c4;
$gray-medium-light: #818181;
$gray-dark-2: #454545;

//Unidentified transparent colors
$white-trans-01 : rgba(255, 255, 255, 0.1);
$black-trans-50 : rgba(0, 0, 0, 0.5);
$black-trans-25 : rgba(0, 0, 0, 0.25);
$black-trans-85 : rgba(0, 0, 0, 0.85);
$gray-trans-purple-27: rgba(41, 40, 42, 0.27);
$red-trans-medium: rgba(210, 63, 64, 0.8);
$blue-trans-medium: rgba(16, 105, 137, 0.8);
$gray-trans-purple-13: rgba(41, 40, 42, 0.13)