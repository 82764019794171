@import '../../../assets/styles/colors';
@import '../../../assets/styles/utils';

.chat {
  font-family: 'Montserrat';
  font-size: 14px;
  &-query {
    float: right;
    background: linear-gradient(180deg, $koyku-red-secondary 0%, $koyku-red 100%);
    border:none;
    
  }
  &-response {
    float: left;
    background: linear-gradient(180deg, $koyku-green 0%, $koyku-green-secondary 100%);
    border:none;
  }
}
