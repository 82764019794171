
*{
  font-family: 'Montserrat';
}

.p-panel {
  margin: inherit;
  padding: inherit;
}

.p-panel .p-panel-content {
  margin: 2px;
  padding: inherit;
}

.p-scrollpanel-content {
  overflow-x: hidden;
}

.image-left {
  margin-left: -2.5rem;
}
