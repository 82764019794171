@import './../../../../assets/styles/colors';
@import './../../../../assets/styles/utils';

.cards-container {
  // align-content: space-between;
  // gap: 20px;
  display: inline-flex !important;
  flex-wrap: wrap;
  justify-content: center;
  gap: 35px;
  &.mobile{
    gap: 18px;
  }

  @media (max-width: 468px) {
    justify-content: center !important;
  }
  .p-card {
    font-family: 'Montserrat' !important;
    width: 220px;
    height: 140px;
    &.mobile {
      width: 175px;
      height: 140px;
      line-height: 24px;
      .p-card-title {
        font-size: 20px !important;
      }
    }
    &.desktop {
    }
    position: relative;
    border-radius: 10px;
    box-shadow: none;
    &:hover,
    &:active {
      cursor: pointer;
      .gradient-background {
        &:before {
          background: linear-gradient(to bottom, rgba(16, 105, 137, 0), rgba(16, 105, 137, 1));
        }
      }
      .icon {
        &.cloud {
          background-image: url(../../../../assets/images/what-we-do-best/cloud-hover.svg);
        }
        &.web {
          background-image: url(../../../../assets/images/what-we-do-best/web-hover.svg);
        }
        &.mobile {
          background-image: url(../../../../assets/images/what-we-do-best/mobile-hover.svg);
        }
        &.cart {
          background-image: url(../../../../assets/images/what-we-do-best/cart-hover.svg);
        }
        &.design {
          background-image: url(../../../../assets/images/what-we-do-best/design-hover.svg);
        }
      }
    }

    .icon {
      width: 100%;
      background-size: auto;
      height: 100px;
      background-repeat: no-repeat;
      background-position: top;
      position: relative;
      top: 7px;
      &.cloud {
        background-image: url(../../../../assets/images/what-we-do-best/cloud.svg);
      }
      &.web {
        background-image: url(../../../../assets/images/what-we-do-best/web.svg);
      }
      &.mobile {
        background-image: url(../../../../assets/images/what-we-do-best/mobile.svg);
      }
      &.cart {
        background-image: url(../../../../assets/images/what-we-do-best/cart.svg);
      }
      &.design {
        background-image: url(../../../../assets/images/what-we-do-best/design.svg);
      }
    }

    .icon-title {
      text-align: center;
      display: block;
      margin-top: 8px;

      .p-avatar {
        position: absolute;
        top: 12px;
      }
      .p-card-title {
        position: absolute;
        top: 91px;
        right: 0;
        left: 0;
        text-align: center;
        font-weight: 500 !important;
        font-size: 22px;
        letter-spacing: -0.015em;
        color: white;
        margin: 0;
      }
    }

    .p-card-body {
      padding: 0;

      .p-card-content {
        padding-top: $ZEROREM;
        padding-bottom: $ZEROREM;
      }
    }
  }
}
