@import './../../../assets/styles/colors';
@import './../../../assets/styles/utils';

.language-selector.mobile {
  position: fixed;
  bottom: 0;
  margin: 0 auto;
  border-top: solid 1px $gray-dark-2;
  left: 10%;
    right: 10%;
  .circle {
    border-radius: 50px;
    height: 45px;
    width: 45px;
    min-width: 45px;
    color: $gray-light;
    font-size: 18px;
    display: inline-block;
    margin: 36px 17px;
    cursor: pointer;    
    background: linear-gradient(black, black) padding-box, linear-gradient(to top, transparent, #d23f3f4d, #D23F40) border-box;
    border-radius: 50em;
    border: 1px solid transparent;    
    & > span {
      position: relative;
      top: 10px;
      display: block;
      width: 100%;
      text-align: center;
    }
    &.active {
      background-color: $koyku-red;
      color: white;
      font-weight: bold;
      font-size: 18px;
      background: linear-gradient(black, black) padding-box, linear-gradient(to top, transparent, #10698961, #106989) border-box;
      border: 3px solid transparent;  
      & > span {
        top: 8px
      }
    }
  }
}
.language-selector.desktop {
  height: 32px;
  position: relative;
  svg.fa-globe {
    // height: 15px;
    color: $koyku-grey;
    margin-right: 9px;
     position: absolute;
     top: 7px;  
     right: 65px;
     @media only screen and (max-width: 1279px) {
      display: none;
     }
  }
  select {
    background: transparent;
    color: white;
    border: none;
    font-weight: bold;
    font-size: 22px;
    line-height: 60px;
    &:focus,
    &:hover {
      color: $koyku-grey;
      background: $gray-dark;
    }
    option {
      box-sizing: none;
    }
  }
}
