@import './assets/styles/colors';
@import './assets/styles/utils';

body {
  margin: 0;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $white-text;
  background-color: $gray-dark;
  background-image: url('/assets/images/design/header-background.jpg');
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
  user-select: none;
}

html {
  // overflow-x: hidden;
  scroll-behavior: smooth;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/*H1 STYLES*/
.desktop {
  &.home-page > div > h1 {
    margin-bottom: 68px;
  }
  h1 {
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-image: linear-gradient(45deg, $gray-trans-purple-13 12%, $koyku-red) 1;
    padding-bottom: 15px !important;
    margin-bottom: 26px;
    margin-top: 50px;
    font-size: 26px;
    &:first-letter {
      font-size: 40px;
    }
  }
}

.mobile h1 {
  font-size: 34px;
  margin: 25px 0;
  padding: 0 30px;
  &:first-letter {
    border-bottom: $koyku-red 4px solid;
    font-size: 45px;
  }
}

h1 {
  text-align: right;
  font-family: 'Montserrat';
  text-transform: lowercase;
  font-weight: normal;
  &:first-letter {
    text-transform: uppercase;
  }
}

h2 {
  text-transform: lowercase;
  font-weight: normal;
  color: $koyku-grey;
  &:first-letter {
    font-size: xx-large;
    font-weight: bold;
    text-transform: uppercase;
  }
}

button.koyku-button {
  $border: 3px;
  color: $white-text;
  font-size: 16px;
  background: black;
  background-clip: padding-box; /* !importanté */
  border: solid $border transparent; /* !importanté */
  border-radius: 5px;
  overflow: inherit;
  padding-left: 12px !important;
  padding-right: 12px !important;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -$border; /* !importanté */
    border-radius: inherit; /* !importanté */
    background: linear-gradient(to right, $koyku-green, $koyku-red);
  }
  &:enabled:hover {
    background-color: $gray-medium;
    color: $white-text;
    border-radius: 5px;
    background-clip: padding-box; /* !importanté */
    border: solid $border transparent; /* !importanté */

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      margin: -$border; /* !importanté */
      border-radius: inherit; /* !importanté */
      background: linear-gradient(to left, $koyku-green, $koyku-red);
    }
  }
}

.header,
.main-content {
  max-width: 990px;
  margin: 0 auto;
}

/* DIALOGS */
.p-dialog {
  box-shadow: 0px 4px 4px $black-trans-25;
  .p-dialog-header {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    background-color: black;
    height: 72px;
    font-weight: 600;
    font-size: 18px;
    font-family: 'Montserrat';
    color: $white;
    padding-left: 30px;
    box-shadow: 0px 4px 4px $black-trans-25;
    z-index: 1;
    .p-dialog-header-icon {
      &:enabled:hover {
        background: url('/assets/images/design/minimize-icon.svg') 50% 50% no-repeat;
      }
      background: url('/assets/images/design/minimize-icon.svg') 50% 50% no-repeat;
      .p-dialog-header-close-icon {
        display: none;
      }
    }
  }
  .p-dialog-content {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    background-color: $gray-dark;
    padding: 0 30px;
  }
}

.p-sidebar {
  &:not(.mobile-navbar-sidebar) {
    .p-sidebar-close {
      display: none;
    }
  }
  overflow-y: scroll;
  .p-sidebar-header {
    background: $black-trans-85;

    .p-sidebar-icon {
      &:enabled:hover {
        background: url('/assets/images/design/two-bars.svg') 50% 50% no-repeat;
      }
      background: url('/assets/images/design/two-bars.svg') 50% 50% no-repeat;
      .p-sidebar-close-icon {
        display: none;
      }
    }
  }
}

.p-sidebar-full {
  .p-sidebar-header {
    padding: 20px 32px;
    height: 72px;
  }
  .p-sidebar-content {
    padding: 0;
  }
  .p-sidebar-close-icon {
    font-size: 30px;
    color: white;
  }

  /*NAVBAR CUSTOM MOBILE*/
  .link-group{
        
    position: absolute;
    top: 50%;    
    transform: translatey( -69%);
    left: 0;
    right: 0;
  }
  .nav-links {
    font-family: 'Montserrat';
    text-align: center;
    margin-bottom: 25px;
    margin-top: 25px;
    font-size: 32px;
    color: $gray-medium-light;
    &.active {
      font-weight: bold;
      color: white;
      &:first-letter {
        border-bottom: $koyku-red 4px solid;
      }
    }
    &:first-letter {
      text-transform: capitalize;
    }
    &:first-child {
      // margin-top: 128px;
    }
  }

  /*END NAVBAR CUSTOM MOBILE*/
}

/*SCROLLBAR*/
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: $gray-medium;
  border: 0px none $white;
  border-radius: 0px;
}
::-webkit-scrollbar-thumb:hover {
  background: $koyku-grey;
}
::-webkit-scrollbar-thumb:active {
  background: $koyku-grey;
}
::-webkit-scrollbar-track {
  background: $gray-dark;
  border: 0px none $white;
  border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
  background: $gray-dark;
}
::-webkit-scrollbar-track:active {
  background: $gray-medium;
}
::-webkit-scrollbar-corner {
  background: transparent;
}

/*CHATBOT*/
.chatbot-dialog {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  font: status-bar;
  .p-dialog-header {
    background: black;
  }
  .p-dialog-content {
    background: $gray-dark;
    //padding: 0;
    padding: 1.25rem 1.25rem 1.25rem 1.25rem;
    .p-scrollpanel-wrapper {
      padding: 20px 20px 10px 20px;
    }
    .input-text {
      padding: 0;
      margin-bottom: 0 !important;
      .p-panel-content {
        margin: 0;
      }
      .full-width {
        padding: 30px;
        background: $gray-dark;
      }
    }
  }
}
.chatbot-sidebar {
  .p-sidebar-header {
  }
  .p-sidebar-content {
    background-color: $gray-medium;
    padding: 0;
    > .p-scrollpanel {
      padding: 20px;
      &.mobile {
        height: calc(100% - 111px);
      }
    }
    > .input-text {
      padding: 28px 22px;
      background-color: $gray-dark;
      margin-bottom: 0 !important;
      .p-input-icon-right {
        .icon {
          top: 12px;
          right: 16px !important;
        }
      }
    }
    form{
     padding: 1.25rem; 
    }
  }
}

/*TEXT STYLING*/
p {
  .title {
    font-weight: 600;
    display: block;
    margin-bottom: 10px;
  }
}

/*GRADIENT BOX*/
.gradient-box {
  display: flex;
  align-items: center;
  //width: 50vw;
  width: 90%;
  margin: auto;
  max-width: 22em;

  position: relative;
  padding: 30% 1em;
  box-sizing: border-box;

  $border: 2px;
  color: #fff;
  background: #000;
  background-clip: padding-box; /* !importanté */
  border: solid $border transparent; /* !importanté */
  border-radius: 500px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -$border; /* !importanté */
    border-radius: inherit; /* !importanté */
    background: linear-gradient(to bottom, $koyku-red, rgba(210, 63, 64, 0.3), rgba(210, 63, 64, 0));
  }
  &:hover {
    &:before {
      background: linear-gradient(to top, $koyku-green, rgba(16, 105, 137, 0.3), rgba(16, 105, 137, 0));
    }
  }
}

.gradient-background {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: black;
  border-radius: 10px;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -2px;
    border-radius: inherit;
    background: linear-gradient(to bottom, rgba(210, 63, 64, 1), rgba(210, 63, 64, 0));
  }
}

/* LINKS STYLE */

a {
  font-weight: 600;
  color: $koyku-red;
  text-decoration: none;
}

/* INPUT & TEXTAREA */
input.p-inputtext,
textarea.p-inputtextarea {
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: #d3d3cb;
  background: #2f2e31;
  border-radius: 5px;
  border: 1px solid #525252;
  padding: 14px 12px;
  &::placeholder {
    color: rgba(211, 211, 203, 1);
  }
  &:enabled:focus {
    border-color: $koyku-red;
    box-shadow: none;
  }
}

/* CHECKBOX */
.p-checkbox {
  .p-checkbox-box.p-highlight {
    background-color: $koyku-red !important;
    border-color: $koyku-red !important;
    .p-checkbox-icon {
      color: white;
    }
  }
}
.p-checkbox-label{
  padding-left: 10px;
}

#our-work-dialog_content {
  scroll-behavior: smooth !important;
}

.p-dialog-mask {
  background: #00000069 !important;
}

body.p-overflow-hidden {
  background-image: none;
  overflow: hidden;
  #root {
    filter: blur(1.5rem);
    position: absolute;
    overflow: hidden;
    //display: none;
    position: relative;
  }
  .p-sidebar.p-sidebar-enter-done{
    overflow: hidden;
    // height: 100% !important;
  }
}


::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

/*DROPDOWN*/
.p-dropdown {
  color: white;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: $koyku-red;
  font-weight: 600;
  background-color: #000;
}
.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: none;
  border-color: $koyku-red;
}
.p-dropdown-label.p-inputtext {
  padding: 5px;
}

.p-sidebar.p-sidebar-enter-done{
  transform: matrix(1, 0, 0, 1, 0, 0);
  position: absolute;
}

//RESPONSE FINE ADJUSTMENTS
//xs-sm
@media (min-width: 0) and (max-width: 575.98px) {
  .cards-container .p-card.mobile {
    width: 146px;
    .p-card-title {
      font-size: 17px !important;
    }
  }
  .our-work .advanced-card.mobile{
    width: 345px;
    .advanced-card__header .advanced-card__title .advanced-card__title-text{
      font-size: 17px;
    }
  }
  .p-sidebar-full .nav-links{
    font-size: 28px;
  }
  .meet-the-team .p-grid .meet-the-greet__avatar.mobile{
    width: 80px;
    height: 80px;
  }
  .p-grid.stack-container{
    gap: 38px 19px;
    div.technology{
      height: 90px;
      width: 90px;
      .technology-name{
        font-size: 18px;
      }
    }
    .logo{
      max-width: 50px;
    }
  }
}
@media (min-width: 0) and (max-width: 767.98px) {
  .our-work-detail .steps{
    display: none;
  }
}
