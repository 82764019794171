@import './../../../assets/styles/colors';
@import './../../../assets/styles/utils';


.navbar{
  transition: all 0.6s ease;
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  &.no-top{
    background: $black-trans-85;
    .home-icon {
      img{
        transition: all 0.6s ease;
        opacity: 1 !important;
      }
    }
  }
  &.top{
    .home-icon {
      img{
        transition: all 0.6s ease;
        opacity: 0 !important;
      }
    }
  }
}


.navbar.desktop {
  height: 110px;
  display: flex;
  text-align: right;
  padding: 47px 20px;
  .navbar__container {
    width: 100%;//990px;
    margin: 0 auto;
    display: flex;
    position: relative;
    img {      
      height: 60px;
      width: 60px;
      position: relative;
      top: -22px;
      left: 47px;
      margin-right: 30px;
    }
    .nav-links-group {
      display: flex;
      width: 100%;
      position: relative;
      top: -6px;
      .nav-links {
        flex-grow: 1;
        font-size: 22px;
        font-weight: normal;
        text-transform: lowercase;
        color: $koyku-grey;
        -webkit-transition: all 0.2s;
        -moz-transition: all 0.2s;
        -o-transition: all 0.2s;
        transition: all 0.2s;
        transition: color 0.7s ease;
        &:first-letter {
          text-transform: uppercase;
        }
        &:hover {

          color: $koyku-red;          
          cursor: pointer;
        }
        &.active {
          color: white;
          font-weight: bold;
          &:first-letter {
            text-transform: uppercase;
            border-bottom: $koyku-red 4px solid;
          }
        }
      }
      .language-selector {
        flex-grow: 1;
      }
    }
  }
}

.navbar.mobile {
  padding: 14px 33px;
  
  .kIcon {
    height: 45px;
    width: 45px;
  }
  .menuIcon {
    width: 30px;
    height: 14px;
    margin-top: 16px;
    cursor: pointer;
    position: absolute;
    right: 30px;
  }
  .nav-links {
    span {
      font-weight: 700;
      font-size: 30px;
    }
  }
}

.mobile-navbar-sidebar{
  
  .p-sidebar-header{
    background: transparent !important;
    .p-sidebar-icon {
      background: none;
      background: url(../../../assets//images//design/close-icon.svg) 50% 50% no-repeat !important;
      &:enabled:hover{
        // background: none !important;
      }
      .p-sidebar-close-icon{
        //display: inline !important;
      }
    }
  }
  .p-sidebar-content{
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.nav-links:hover{
  cursor: pointer;
}