@import './assets/styles/colors';
@import './assets/styles/utils';

.p-panel {
  border-radius: inherit;
  box-shadow: inherit;
  margin: inherit;
  padding: inherit;
}
.p-panel .p-panel-content {
  background: inherit;
  color: inherit;
}

.red-grad {
  --b:2px;  /* border width*/
  --r:10px; /* the radius */

  color: #313149;
  padding: calc(var(--b) + 5px);
  display: inline-block;
  margin: 75px 0;
  position:relative;
  z-index:0;
  &:hover{
    &:before{
      background: var(--c,linear-gradient(to bottom, transparent, $koyku-green)) !important; 
    }
  }
  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    inset: 0;
    padding: var(--b);
    border-radius: var(--r);
    background: var(--c,linear-gradient(to bottom, $koyku-red, transparent)); 
    -webkit-mask: 
       linear-gradient(#fff 0 0) content-box, 
       linear-gradient(#fff 0 0);
            mask: 
       linear-gradient(#fff 0 0) content-box, 
       linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude;
            
  }
}
