@import './../../../../assets/styles/colors';
@import './../../../../assets/styles/utils';

.landing {
  margin-top: 182px;
  .koyku-vertical {
    opacity: 1;
    transition: all 0.2s ease;
    &.hide{
      opacity: 0;
    }
    font-family: 'Montserrat';
    font-size: 67px;
    text-transform: uppercase;
    writing-mode: vertical-rl;
    text-orientation: upright;
    padding: 0 15px 0 20px;
    &:first-letter {
      color: $koyku-red;
    }
    img{
      height: 385px;
      width: 51px;
    }
  }
  .landing_container {
    font-size: 18px;
    text-align: right;
    font-family: 'Montserrat Alternates Light';
    line-height: 30px;
    font-weight: 500;
    

    .landing_container__title {
      font-size: 34px;
      font-weight: 800;
      font-family: 'Montserrat Alternates Bold';
    }
    .landing_container__text {
    }
    .landing_container__footer {
        font-family: 'Montserrat';
      font-weight: 900;
      & span {
        color: $koyku-red;
      }
    }
  }
}

.desktop.landing{
    .landing_container {
        font-size: 28px;
        line-height: 48px;
        padding-left: 80px;
        .landing_container__title {
          font-size: 38px;
        }
        .landing_container__footer {
            font-size: 22px;

          }
    }
}

.mobile{
  .landing_container {
    margin-right: 0 !important;
  }
}