@import './../../../../../assets/styles/colors';
@import './../../../../../assets/styles/utils';

.content {
  &.mobile {
    .content__header{
      width: 100%;
      margin: auto;
      .p-image{
        width: 100%;
        img{
          width: 100%;
        }
      }
    }
    .content__main {
      padding: 23px;
      > :last-child {
        padding: 0;
      }
      .p-col-12 {
        text-align: center !important;
        .koyku-button {
          margin-left: 10px !important;
        }
      }
    }
  }
  
  //margin: 0 -29px;
  .content__header {
    background-color: rgba(255, 255, 255, 0.1);
    height: 270px;
    margin: 0 -30px;
  }
  .content__main {
    padding: 30px;

    width: 100%;
    > .section {
      padding: 0;
      h3 {
        padding-bottom: 10px;
      }
    }
    > :last-child {
      padding: 18px 0 0 15px;
    }

    .benefits-row {
      .benefit {
        padding-right: 30px;
        padding-bottom: 30px;
        .benefit-number {
          height: 28px;
          width: 28px;
          background-color: $koyku-red;
          border-radius: 50px;
          position: relative;
          display: inline-block;
          margin-right: 20px;
          > span {
            position: absolute;
            width: 100%;
            text-align: center;
            top: 2px;
            color: white;
            font-weight: 700;
            font-size: 20px;
          }
        }
        .benefit-text {
          font-weight: 400;
          font-size: 20px;
          color: white;
          position: relative;
          display: inline-flex;
          width: calc(100% - 48px);
          top: -7px;
        }
      }
    }
    .why {
      position: sticky;
      p {
        color: white;
        position: inherit;
        padding: 25px 30px;
      }
    }

    h3 {
      padding-left: 0 !important;
      padding-right: 0 !important;
      font-weight: 500;
      font-size: 26px;
      color: $white;
    }

    .includes-content {
      padding: 0;
      .includes-row {
        display: flex;
        position: relative;

        z-index: 1;
        > div {
          padding-left: 0;
          padding-right: 0;
        }
        .p-avatar-circle {
          top: 50%;
          transform: translateY(-50%);
          position: absolute;
          font-weight: bold;
          font-size: 24px;
          height: 40px;
          width: 40px;
        }
        .includes-text {
          margin: 0;
          text-align: justify;
          font-weight: normal;
          font-size: 16px;
          line-height: 20px;
          color: $koyku-grey;
          font-family: 'Montserrat';
        }
        .p-col-12 {
          position: relative;
          text-align: right;
          .koybotIcon {
            height: 66px;
            display: inline-block;
          }
          .koyku-button {
            position: relative;
            top: -10px;
            margin-left: 40px;
          }
        }
      }
    }
  }
}
