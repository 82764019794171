.cookie-banner {
    &.hidden{
        display: none;
    }
  background-color: black;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.25);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: space-between;
  height: 90px;
  .cookie-content {
    display: inline-block;
    .icon {
      height: 50px;
      width: 50px;
      display: inline-block;
      margin: 20px 30px;
    }
    .text {
      display: inline-block;
      position: relative;
      top: -25px;
      .title {
        font-weight: 600;
        font-size: 18px;
      }
      p {
        font-weight: 400;
        font-size: 14px;
        margin-top: 6px;
        a {
          color: #d23f40;
          font-weight: 700;
          font-size: 14px;
          text-decoration: none;
          cursor: pointer;
        }
      }
    }
  }
  .button-group {
    display: inline-block;
    position: absolute;
    right: 0;
    top: 23px;
    font-weight: 500;
    font-size: 16px;
    button {
        cursor: pointer;
      color: #f9f9f9;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      padding: 12px 24px;
      background-color: black;
      border-radius: 5px;
      border: none;
      width: 144px;
      margin-right: 30px;
      &.main {
          background: #D23F40;
          
      }
    }
  }
}
