@import './../../../../assets/styles/colors';
@import './../../../../assets/styles/utils';

.our-work{
  .distribute.desktop{
    position: relative;
    top:10px;
    display: inline-flex !important;
    flex-wrap: wrap;
    gap: 18px;
    justify-content: center;
  }
  .advanced-card {
    border-radius: 11px;
    margin-bottom: 23px;
    height: 336px;
    width: 480px;
    position: relative;
  
    /***** HOVER ***************************/
    &:hover {
      cursor: pointer;
      .gradient-background {
        &:before {
          background: linear-gradient(to bottom, rgba(16, 105, 137, 0), rgba(16, 105, 137, 1));
        }
      }
      .advanced-card__title {
        background-color: rgba(16, 105, 137, 0.75) !important;
      }
    }
  
    /***** MOBILE ***************************/
    &.mobile {
      width: 368px;
      .advanced-card__content {
        bottom: 1px;
      }
    }
  
    .advanced-card__header {
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      height: 230px;
      .p-image {
        position: absolute;
        top: 0px;
        width: 100%;
        height: 223px;
        img {
          border-top-right-radius: 10px;
          border-top-left-radius: 10px;
          height: 226px;
          position: absolute;
          top: 0px;
          left: 0px;
          width: calc(100% - 0px);
          filter: brightness(0.5);
          object-fit: cover
        }
      }
      .advanced-card__title {
        position: absolute;
        //height: 84px;
        bottom: 4px;
        left: 0;
        right: 0;
        margin: 0;
        background-color: $red-trans-medium;
        padding: 14px 32px;
        span {
          display: block;
        }
        .advanced-card__title-text {
          font-size: 20px;
          font-weight: bold;
        }
        .advanced-card__title-date {
          font-size: 16px;
          font-weight: normal;
          margin-top: 7px;
        }
      }
    }
    .advanced-card__content {
      padding: 20px;
      height: 106px;
      // background-color: black;
      font-size: 16px;
      color: $koyku-grey;
      line-height: 22px;
      text-align: justify;
      letter-spacing: -0.015em;
      margin: 0;
      position: absolute;
      bottom: 3px;
      left: 2px;
      width: calc(100% - 4px);
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
    }
  }

  .p-sidebar-content {
    position: relative;
    .mobile.footer {
      left: 0;
      right: 0;
      position: absolute;
    }
  }
  
  .distribute {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 27px;
    justify-content: space-between;
    &.mobile{      
        justify-content: center;      
    }
  }

}
.our-work-dialog{
  &.p-dialog .p-dialog-content{
    padding: 0 !important;
    scroll-padding-top: 150px;
    overflow-x: hidden;
  }
}

