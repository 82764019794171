@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/utils';

.meet-the-team {
  .p-grid {
    margin: 0 0 0 0;
    justify-content: space-around;
    width: 100%;
    display: inline-flex;    
    text-align: center;
    .meet-the-greet__avatar {
      &:hover{
        cursor: pointer;
      }
      .onlyHover{
        display: none;
      }
      &.mobile {
        &:active {
          img.normal{
            display:none;
          }
          .onlyHover{
            display: block !important;
          }
        }
        width: 102px;
        height: 102px;
        .top-layer {
          img {
            height: 72px;
          }

        }
        .bottom-layer {
          display: none !important;
        }
        .avatar-name{
          padding-top: 7px;
          display: inline-block;
        }
      }
      width: 212px;
      height: 212px;
      transition: $ANIMATIONDEFAULT;
      background-image: url(../../../../assets/images/frames/avatar.svg);
      background-size: cover;
      &:active {
        transition: $ANIMATIONDEFAULT;
        background-image: url(../../../../assets/images/frames/avatar-hover.svg);
      }
      &.desktop:hover {
        transition: $ANIMATIONDEFAULT;
        background-image: url(../../../../assets/images/frames/avatar-hover.svg);
        .top-layer {
          display: none;
        }
        .bottom-layer {
          display: block;
        }
      }
      .top-layer,
      .bottom-layer {
        height: 100%;
      }
      .top-layer {
        img {
          height: 122px;
        }
      }
      .bottom-layer {
        display: none;
        .name-layer {
          font-weight: 600;
          font-size: 28px;
          color: white;
          margin: 15px 0 11px 0;
        }
        .avatar-layer {
          display: block;
          margin: 0 auto;
          position: relative;
          top: -18px;
          height: 122px;
        }
        .go-to-linkedin-layer {
          font-weight: 300;
          font-size: 12px;
          display: inline-block;
          width: 125px;
          text-align: center;
          margin-top: 10px;
          color:white;
          position: relative;
          top: -33px;
        }
      }
    }
  }
  .click-on {
    margin-top: 24px;
    font-weight: 500;
    font-size: 14px;
    line-height: 25px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #d3d3cb;    
  }
  &.desktop .click-on{
    display: none;
  }
  &.mobile .click-on{
    margin-top: 50px !important;
  }
}
